import { css } from "styled-components";
import tw from "twin.macro";

import StarIcon from "../../assets/icons/star.svg";
import { floor } from "../../utils/Math";

const inactiveIconCss = tw`svg text-gray`;
const activeIconCss = tw`svg text-theme`;

const Size = {
  small: "small",
  large: "large",
} as const;

interface Props {
  rate: number | undefined;
  size?: keyof typeof Size;
  showAverage?: boolean;
  className?: string;
}

const Star = ({ index, rate, size }: Props & { index: number }) => {
  const decimalPart = rate - Math.floor(rate);
  const intPart = Math.floor(rate);

  const leftFill =
    intPart >= index + 1 || (intPart === index && decimalPart > 0);
  const rightFill = intPart >= index + 1;

  const leftCss =
    size === Size.small
      ? css({ transform: "scale(.6)" })
      : css({ transform: "scale(.768)" });
  const rightCss =
    size === Size.small
      ? [tw`right-[-13px]`, css({ transform: "scale(.6)" })]
      : [tw`right-[-8px]`, css({ transform: "scale(.768)" })];

  return (
    <div tw="relative mr-1" css={size === Size.small ? tw`w-1` : tw`w-2.5`}>
      <StarIcon css={[leftCss, leftFill ? activeIconCss : inactiveIconCss]} />
      <StarIcon
        tw="svg absolute top-0"
        css={[rightCss, rightFill ? activeIconCss : inactiveIconCss]}
        viewBox="6.5, 0, 13, 13"
      />
    </div>
  );
};

export const StarRating = ({
  rate = 0,
  size,
  showAverage = true,
  className,
}: Props) => {
  return (
    <div tw="flex items-center" className={className}>
      {[...Array(5)].map((value, index) => (
        <Star key={index} index={index} rate={rate} size={size} />
      ))}
      {showAverage && (
        <div
          tw="font-base text-theme ml-1.5 mt-[1px]"
          css={
            size === Size.small
              ? tw`text-[8px] tracking-[-0.26px] leading-none`
              : tw`text-xs tracking-[.35px] leading-none`
          }
        >
          {floor(rate, 1).toFixed(1)}
        </div>
      )}
    </div>
  );
};
