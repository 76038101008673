import {
  FortuneTeller,
  Fortune,
  TelFortune,
  SubscriptionPlan,
} from "../../types";

const getOriginalPrice = (menu: Fortune | TelFortune | SubscriptionPlan) => {
  if (!menu) return undefined;

  if ("price" in menu) {
    return menu.price;
  } else if ("costPerMinute" in menu) {
    return menu.costPerMinute;
  } else if ("amount" in menu) {
    return menu.amount;
  } else {
    return undefined;
  }
};

/** 価格が安い順でメニューを返すAPIの仕様が前提 */
const getMinimumChatMenuPrice = (fortuneTeller: FortuneTeller) => {
  return (fortuneTeller.fortunes[0]?.price || 0).toLocaleString();
};

/** 価格が安い順でメニューを返すAPIの仕様が前提 */
const getMinimumTelMenuPrice = (fortuneTeller: FortuneTeller) => {
  return (fortuneTeller.telFortunes[0]?.costPerMinute || 0).toLocaleString();
};

export const usePrice = () => {
  return {
    getOriginalPrice,
    getMinimumChatMenuPrice,
    getMinimumTelMenuPrice,
  };
};
