import tw from "twin.macro";

import { getMenuInfo } from "../utils/getMenuInfo";
import { MenuTag } from "../../../components/atoms/MenuTag";
import { usePrice } from "../../shared/usePrice";
import { FortuneTeller, Fortune, TelFortune } from "../../../types";
import { MenuGallelyCardProfile } from "./MenuGallelyCardProfile";
import { MenuGallelyCardThumbnail } from "./MenuGallelyCardThumbnail";
import { numberToCurrency } from "../../../utils/numberToCurrency";
import { FreeUsage } from "./FreeUsage";

const Col = tw.div`flex flex-col flex-1`;

const Row = tw.div`flex items-end`;

const hasFreeUsageCss = tw`text-2xs text-secondary`;

interface Props {
  menu: Fortune | TelFortune;
  fortuneTeller?: FortuneTeller;
  hasFreeUsage?: boolean;
  onClickFav?: (menu: Fortune | TelFortune) => void;
}

/**
 * @param menu チャット鑑定メニューまたは電話鑑定メニュー
 * @param fortuneTeller 占い師
 * @param onClickFav ハートアイコンをクリックしたときのコールバック
 * @returns
 */
export const MenuGallelyCard = ({
  menu,
  fortuneTeller,
  hasFreeUsage,
  onClickFav,
  ...rest
}: Props) => {
  const { getOriginalPrice } = usePrice();

  const { avatar, fallbackAvatar, fortuneTellerName, label } = getMenuInfo(
    menu,
    fortuneTeller
  );
  const price = getOriginalPrice(menu);
  const unit = menu?.type === "tel" && "/分";

  const handleClickFav = onClickFav ? () => onClickFav(menu) : null;

  return (
    <Col tw="w-40 h-[252px]" css={hasFreeUsage && tw`h-[280px]`} {...rest}>
      <MenuGallelyCardThumbnail
        src={menu?.imageUrl}
        isOnlyFirstTime={menu?.isOnlyFirstTime}
        isFavorited={menu?.isFavorited}
        isOfficial={
          menu?.type === "tel" &&
          (fortuneTeller?.isOfficial || menu.fortuneTeller?.isOfficial)
        }
        onClickFav={handleClickFav}
      />
      <Col tw="px-1.5">
        <MenuGallelyCardProfile
          tw="mt-3.5"
          src={avatar}
          fallbackSrc={fallbackAvatar}
          name={fortuneTellerName}
          reviewScore={menu?.averageScore}
        />
        <p tw="font-base text-[13px] leading-5 tracking-[.32px] line-clamp-3 mt-1.5 flex-1 text-left">
          {menu?.title}
        </p>
        <Row tw="justify-between mt-2.5">
          <div tw="flex">
            {hasFreeUsage && (
              <p css={hasFreeUsageCss} tw="mr-1">
                通常時
              </p>
            )}
            <div
              css={
                hasFreeUsage
                  ? [hasFreeUsageCss, tw`line-through`]
                  : tw`text-xs text-black leading-3 mb-[.5px] tracking-[.3px]`
              }
            >
              {numberToCurrency(price)}
              {unit}
            </div>
          </div>
          <MenuTag tw="mb-0.5">{label}</MenuTag>
        </Row>
        {hasFreeUsage && <FreeUsage />}
      </Col>
    </Col>
  );
};
