import { HTMLAttributes } from "react";

export const FreeUsage = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      tw="grid grid-flow-col items-center"
      css={{ gridAutoColumns: "1fr", gridColumnGap: 8 }}
      {...props}
    >
      <div tw="bg-[#FE4FD0] rounded-sm px-0.5 h-4 flex-center">
        <span tw="font-semibold text-white text-[9px] tracking-[.27px] mt-[2.5px]">
          クーポン利用で
        </span>
      </div>
      <span tw="font-semibold text-2xs tracking-[.45px] text-[#FE4FD0]">
        <span tw="text-xl leading-none">0</span>
        円~
      </span>
    </div>
  );
};
