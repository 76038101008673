import { FC, HTMLAttributes } from "react";
import tw from "twin.macro";

const Container = tw.div`border border-solid border-theme rounded-[3px] text-theme text-[9px] flex-center px-0.5 leading-none`;

export const MenuTag: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  return (
    <Container {...props}>
      <span tw="mt-0.5">{children}</span>
    </Container>
  );
};
