import { Avatar } from "../../../components/atoms/Avatar";
import { StarRating } from "../../../components/molecules/StarRating";

interface Props {
  src: string;
  fallbackSrc?: string;
  name: string;
  reviewScore: number;
  className?: string;
}

export const MenuGallelyCardProfile = ({
  src,
  fallbackSrc,
  name,
  reviewScore,
  className,
}: Props) => {
  return (
    <div tw="flex items-center w-full h-[37px]" className={className}>
      <Avatar src={src} fallback={fallbackSrc} size={37} tw="mr-1" />
      <div tw="truncate">
        <div tw="font-base text-[11px] leading-[11px] truncate">{name}</div>
        <StarRating rate={reviewScore} size="small" tw="mt-1.5" />
      </div>
    </div>
  );
};
