import { HTMLAttributes } from "react";
import tw from "twin.macro";

const Container = tw.div`bg-gradient-theme-to-l rounded-full text-white font-semibold text-2xs flex-center px-2 py-1.5 leading-none`;

export const OnlyFirstTimeMark = (props: HTMLAttributes<HTMLDivElement>) => (
  <Container {...props}>
    <span tw="mt-[1px]">初回限定</span>
  </Container>
);
