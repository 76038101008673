import { MouseEventHandler, useEffect, useState } from "react";
import tw from "twin.macro";

import FavoriteIcon from "../../../assets/icons/favorite2.svg";
import { IconButton as IconButtonBase } from "../../../components/atoms/IconButton";
import { Image } from "../../../components/atoms/Image";
import { OnlyFirstTimeMark } from "./OnlyFirstTimeMark";
import { OfficialBadge } from "../../shared/components/OfficialBadge";

const FavButton = tw(IconButtonBase)`absolute top-2 right-2 w-4 h-4 z-10`;

interface Props {
  src: string;
  isFavorited?: boolean;
  isOnlyFirstTime?: boolean;
  isOfficial?: boolean;
  onClickFav?: () => void;
}

export const MenuGallelyCardThumbnail = ({
  src,
  isFavorited,
  isOnlyFirstTime,
  isOfficial,
  onClickFav,
}: Props) => {
  const [favorited, setFavorited] = useState(isFavorited);

  useEffect(() => {
    setFavorited(isFavorited);
  }, [isFavorited]);

  const handleFavorited: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onClickFav();
    setFavorited(!favorited);
  };

  return (
    <div tw="relative">
      {isOnlyFirstTime && (
        <OnlyFirstTimeMark tw="absolute bottom-1.5 left-1.5 z-10" />
      )}
      {onClickFav && (
        <FavButton
          icon={
            <FavoriteIcon
              tw="svg"
              css={favorited ? tw`text-theme` : tw`text-white`}
            />
          }
          onClick={handleFavorited}
        />
      )}
      {isOfficial && (
        <div tw="absolute top-0 left-3 w-6 h-6 z-10">
          <OfficialBadge size={24} />
        </div>
      )}
      <Image
        tw="rounded h-[112px]"
        src={src}
        layout="dynamic"
        objectFit="cover"
      />
    </div>
  );
};
