import {
  FortuneTeller,
  Fortune,
  TelFortune,
  SubscriptionPlan,
} from "../../../types";

interface UseMenuInfo {
  avatar: string | undefined;
  fallbackAvatar: string | undefined;
  fortuneTellerName: string;
  label: string;
}

export const getMenuInfo = (
  menu: Fortune | TelFortune | SubscriptionPlan,
  fortuneTeller?: FortuneTeller
): UseMenuInfo => {
  const avatar = fortuneTeller?.imageUrl || menu?.fortuneTeller?.imageUrl;

  const fallbackAvatar =
    fortuneTeller?.thumbnailUrl || menu?.fortuneTeller?.thumbnailUrl;

  const fortuneTellerName = fortuneTeller
    ? fortuneTeller.name
    : menu?.fortuneTeller?.name;

  const label = (() => {
    switch (menu?.type) {
      case "chat":
        return "チャット占い";
      case "tel":
        return "電話占い";
      case "subscription":
        return "サブスク";
    }
  })();

  return {
    avatar,
    fallbackAvatar,
    fortuneTellerName,
    label,
  };
};
