import { ImageProps } from "next/image";
import { Image } from "../../../components/atoms/Image";

interface Props extends Partial<ImageProps> {
  size?: number;
}

export const OfficialBadge = ({ size, ...imageProps }: Props) => {
  return (
    <Image
      src="/images/official_badge.png"
      alt="公式占い師"
      width={size}
      height={size}
      layout="responsive"
      {...imageProps}
    />
  );
};
