export const round = (value: number, decimal?: number) => {
  return decimal
    ? Math.round(value * 10 ** decimal) / 10 ** decimal
    : Math.round(value);
};

export const floor = (value: number, decimal?: number) => {
  return decimal
    ? Math.floor(value * 10 ** decimal) / 10 ** decimal
    : Math.floor(value);
};
